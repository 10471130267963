#surveyElement-cedav {

    .alert.alert-danger{
        span{
            color: #dc3545;
        }
    }

    .progress-bar {
        background-color:  #3CB371;
    }

    .sv_prev_btn{
        background-color:  #3CB371 !important;
    }

    .sv_next_btn{
        background-color:  #3CB371 !important;
    }

    .sv_complete_btn{
        background-color: #3CB371 !important;
    }
}

